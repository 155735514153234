<template>
  <div class="flex-shrink-8 flex-grow-1 px-sm-0 pb-5">

    <!-- Header Panel -->
    <div class="panel aboutPanel">
      <div class="container">
        <div class="row">
          <div class="col-md-9 col-lg-6 pt-5 mt-4">
            <h3>The data that we have compiled about consumers and businesses is derived from public 
              sources, opt-in mobile applications, and from statistical generated propensity models.</h3>
            <a class="btn bg-white text-info mt-4" href="#data">Our Data Sources</a>
          </div>
        </div>
      </div>
    </div>
    <!-- End Header Panel -->

    <div class="container-fluid mt-5">
      <div class="container mx-auto">
        <div class="row mb-5 pb-5 mt-5 pt-5"> 

          <!-- Left col -->
          <div class="col-md-7 pr-md-5"> 
            <h2>About Our Data</h2>

            <ul>
              <li>This data can only be used by approved advertisers that adhere to industry and 
                government standards as detailed by the IAB, DAA, ANA, GDPR and CCPA.</li>
              <li>The data will only be used to promote relevant products and services.</li>
              <li>We do not collect or hold any sensitive data about you such as credit card numbers, 
                social security information, personal health information or prescriptions.</li>
              <li>We only share data with approved partners using anonymous digital IDs.  These 
                IDs only work on these individual platforms to support a campaign and are deleted 
                after the campaign.</li>
            </ul>

            <img class="mx-auto mb-5 mt-4" src="images/trustLogos.jpg" width="100%">

            <h2 id="data">Our Data Sources</h2>

            <p>Semcasting is a compiler of household and business data from public and opt-in sources. 
              We use our patented machine learning and modeling technology to align this data to location 
              and summarized demographic and Firmographic information. We do not use cookies, track users, 
              collect, or compile sensitive information. Our data goes through rigorous testing to validate 
              accuracy and are updated quarterly.</p>

            <p><b>Our data sources include:</b></p>

            <div class="mw-1200 mx-auto">
              <div class="row">
                <div class="col-lg-6">
                  <ul class="small">
                  <li>American Community Survey</li>
                  <li>Earnings and Employment – Census 2018</li>
                  <li>ACS - Income and Poverty in the United States</li>
                  <li>Statistics Canada: Canadian Census</li>
                  <li>Voter Registration Files 2010 through 2019</li>
                  <li>Federal Reserve Survey of Consumer<br>
                  &nbsp;&nbsp;Finances – Savings &amp; Insurance</li>
                  <li>Federal Reserve Data – Vacation Properties</li>
                  <li>Bureau of Labor Statistics – Unemployment</li>
                  <li>Bureau of Economic Analysis – Recession Data</li>
                  <li>Department of Treasury – Interest Rates</li>
                  <li>Internal Revenue (IRS) Income, Tax, Charitable Giving</li>
                  <li>Home Mortgage Disclosure Act (HMDA)</li>
                  <li>Census - Housing Affordability Data Tables</li>
                  <li>People Data Labs</li> 
                  </ul>
                </div>
                <div class="col-sm-6">
                  <ul class="small">
                  <li>American Housing Survey (AHS)</li>
                  <li>Centers for Medicare &amp; Medicaid Services</li>
                  <li>Center for Disease Control (CDC)</li>
                  <li>Department of Health</li>
                  <li>Data.gov</li>
                  <li>Healthcare.gov (Medicare)</li>
                  <br>
                  <li>Digital Envoy – Digital Element IP Location</li>
                  <li>Kochava Labs – Mobile App opt-in registrations</li>
                  <li>Cubeiq – Mobile App opt-in registrations</li>
                  <li>Complementics – Mobile app opt-in registrations</li>
                  <li>Datastream - Auto Warranty &amp; Make/Model Service</li>
                  <li>MapLarge – Interactive Mapping &amp; Distance</li>
                  <li>Digital Map Products – U.S. Parcel Mapping</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- Right col -->
          <div class="col-md-5">
            <img class="mx-auto mb-5" src="images/sourceLogos.jpg" width="100%" style="max-width: 300px;">
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "AboutView"
}
</script>